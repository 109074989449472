.todo_hidden{
    display: none;
}

.todo__container{
    display: flex;
    justify-content: center;
}

.todo__input{
    margin: 0 10px 0 0;
    max-width: 70%;
}

.todo__input_theme-dark{
    background-color: #0f1014;
    color: #FFFFFF;
}

.todo__button{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.todo__button_theme-dark{
    filter: invert(100%) brightness(114%) contrast(115%);
}

.todo__button_add{
    background-image: url(../../images/buttons/add.svg);
}

.todo__button_delete{
    background-image: url(../../images/buttons/delete.svg);
}

.todo__list{
    list-style: none;
    margin: 5px 0;
    padding: 0;
    text-align: left;
}

.todo__list_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.todo__list_item_text{
    max-width: 80%;
    margin: 0 0 0 10px;
}

.todo__list_item_text-done{
    text-decoration: line-through;
}

@media screen and (max-width: 380px) {
    .todo__list_item_text {
        max-width: 70%;
    }
}
