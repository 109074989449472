.mode-checkbox {
    display: flex;
    padding: 29px 0 48px;
    align-items: center;
  }
  
  .mode-checkbox__label {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
  }
  
  .mode-checkbox__input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .mode-checkbox__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }
  .mode-checkbox__slider::before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    left: 6px;
    bottom: 6px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  .mode-checkbox__input:checked + .mode-checkbox__slider {
    background-color: #A646F0;
  }
  
  .mode-checkbox__input:checked + .mode-checkbox__slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  .mode-checkbox__img{
    width: 40px;
    height: 40px;
  }

  .mode-checkbox__img_theme-dark{
    filter: invert(100%) brightness(114%) contrast(115%);
  }