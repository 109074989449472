.notes__list_wrapper{
    width: 100%;
    min-height: 100%;
}

.notes__list{
    max-width: 1140px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, 364px);
    grid-row-gap: 30px;
    grid-column-gap: 24px;
    justify-content: center;
    padding: 20px;
}

.notes__list_title{
    max-width: 1140px;
    margin: auto;
    text-align: left;
}

.notes__list_pinned{
    border-bottom: 1px solid white;
}
@media screen and (max-width: 380px) {
    .notes__list{
        grid-template-columns: repeat(auto-fit, 310px);
    }
}


@media screen and (max-width: 320px) {
    .notes__list{
        grid-template-columns: repeat(auto-fit, 250px);
    }
}