.search{
    display: flex;
    max-width: 1140px;
    margin: auto;
    padding: 20px;
    align-items: center;
    justify-content: space-around;
}
.search__input{
    padding: 15px 0;
    width: 70%;
    border: 1px solid;
    border-radius: 20px;
    background-color: transparent;
    outline: none;
    text-align: center;
    font-size: 28px;
    line-height: 1.21;
}
.search__input:focus{
    border-color: #A646F0;
}

.search__input_theme-dark{
    color: #FFFFFF;
}

.search__img{
    background-image: url(../../images/buttons/search.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: transparent;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
}

.search__new-note-btn{
    background-image: url(../../images/buttons/add.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    transition: opacity 0.3s ease 0.1s;
    width: 50px;
    height: 50px;
    animation: buttonRotate 5s linear  infinite;
}

.search__new-note-btn:hover{
    cursor: pointer;
    opacity: 0.6;
}

@keyframes buttonRotate{
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(180deg);
        background-color: #A646F0;
    }
    100%{
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 380px) {
    .search__input{
        font-size: 15px;
        width: 50%;
    }
}