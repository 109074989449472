.note{
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    max-height: 300px;
    padding: 20px;
    position: relative;
    color: #000;
}

.note__title{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.note__text{
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.note__edit:hover{
    background-image: url(../../images/buttons/edit.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.note__list{
    list-style: none;
    margin: 5px 0;
    padding: 0;
    text-align: left;
}

.note__list li:nth-child(n+6) {
    display: none;
}

.note__list_item{
    display: flex;
    max-width: 100%;
    overflow: hidden;
}

.note__list_item_text{
    margin-left: 5px;
}

.note__date-created{
    font-size: 0.7em;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.note__button{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: transparent;
    border: none;
    outline: none;
    width: 20px;
    height: 20px;
    position: absolute;
    cursor: pointer;
}

.note__button_pin{
    background-image: url(../../images/buttons/pin.svg);
    bottom: 5px;
    left: 5px;
}

.note__button_unpin{
    background-image: url(../../images/buttons/unpin.svg);
    bottom: 5px;
    left: 5px;
}

.note__button_delete{
    background-image: url(../../images/buttons/delete.svg);
    top: 5px;
    right: 5px;
}